<template>
  <!-- Coming soon page-->
  <div class="misc-wrapper">
    <b-link
      class="brand-logo"
      href="/"
    >
      <!-- <vuexy-logo /> -->
      <b-img
        fluid
        :src="icono"
        width="30"
      />
      <h2 class="brand-text text-primary ml-1">
        {{ titulo }}
      </h2>
    </b-link>

    <div class="misc-inner p-sm-3 mt-2">
      <div class="w-100 text-center mt-2" />
      <div>

        <Formulario
          :id-formulario="idFormulario"
        />

      </div>
    </div>
  </div>
</template>

<script>
import {
  BLink, BImg,
} from 'bootstrap-vue'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import Formulario from '@/views/administrador/gestion-formulario/formulario/Formulario.vue'
import { mapState } from 'vuex'

export default {
  components: {
    Formulario,
    // VuexyLogo,
    BImg,
    BLink,
  },

  data() {
    return {
      idFormulario: parseInt(this.$route.params.id_formulariox, 10),
    }
  },
  computed: {
    ...mapState('ajustesApp', ['titulo', 'icono']),

  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-misc.scss';
</style>
